

<div class="border margin">
  <div style="background-color: #ffffff;">
    <form [formGroup]="searchForm" class="form">
    <div class="row" style="padding-top: 20px; margin: 0 20px">
      <div class="filter-margin" style="width: 14%!important">
        <mat-form-field appearance="legacy" class="date-picker-field">
          <mat-label class="date-picker-label">Submitted Start Date</mat-label>
          <input [disabled]="disabledFields" matInput formControlName="startDate" (keyup)="clearStartDate($event)" [matDatepicker]="picker" (dateChange)="logStartDate($event)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="filter-margin" style="width: 14%!important;">
        <mat-form-field appearance="legacy" class="date-picker-field">
          <mat-label class="date-picker-label">Submitted End Date</mat-label>
          <input [disabled]="disabledFields" matInput formControlName="endDate" (keyup)="clearEndDate($event)" [matDatepicker]="picker2" (dateChange)="logEndDate($event)">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
     </div>
     <div class="filter-margin" style="width:14%!important">
<!--      <mat-form-field appearance="legacy">
      <mat-label>Buyer Name</mat-label>
      <mat-select (selectionChange)="buyer($event)">
        <mat-option value="all">All</mat-option>
        <mat-option *ngFor="let retailer of retailerList" [value]="retailer.retailerName">{{retailer.retailerName}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field appearance="legacy" class="buyerName-field" >
      <fieldset [disabled]="disabledFields">
        <input placeholder="Buyer Name" matInput formControlName="myControl" (keyup)="disableOrederId($event)" [matAutocomplete]="auto" [(ngModel)]="retailerName">
        <mat-autocomplete #auto="matAutocomplete"  >
          <mat-option (click)="buyerSelect(retailer)" *ngFor="let retailer of filteredOptions | async" [value]="retailer">
            {{retailer}}
          </mat-option>
        </mat-autocomplete>
      </fieldset>
    </mat-form-field>
    </div>
    <div class="filter-margin" style="width: 14%!important">
      <mat-form-field appearance="legacy" class="status-field">
        <mat-label>Groups</mat-label>
        <mat-select [disabled]="disabledFields" #select formControlName="buyerGroup" multiple >
          <!-- <mat-option value="All"  (click)="selectAll()">All</mat-option> -->
          <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelected"
                            [ngModelOptions]="{standalone: true}"
                            (change)="selectAllBuyerGroup()">Select All</mat-checkbox>
        </div>
          <mat-option  (click)="selectBuyerGroup()" *ngFor="let buyergroup of buyerGroup" [value]="buyergroup.id">{{buyergroup.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div class="filter-margin" style="width: 14%!important"> 
        <mat-form-field appearance="legacy" class="orderId-field">
          <mat-label>Order ID</mat-label>
          <fieldset [disabled]="disabledOrderIdFields">
            <input  matInput type="text" formControlName="orderId" (keyup)="saveID($event)">  
          </fieldset>
        </mat-form-field>
      </div>
      <div class="filter-margin" style="width: 14%!important">
        <mat-form-field appearance="legacy" class="status-field">
          <mat-label>Status</mat-label>
          <mat-select [disabled]="disabledFields" (selectionChange)="status($event)" formControlName="status">
            <mat-option *ngFor="let status of statuses" [value]="status">{{status}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="filter-margin action-field" >
        <button  mat-icon-button color="primary" matTooltip="Search Orders" (click)="search()"><mat-icon>search</mat-icon></button>
        &nbsp;
      <button  mat-icon-button color="primary" matTooltip="Reset Search Fields" (click)="reset()"><mat-icon>refresh</mat-icon></button>
      &nbsp;
      <button mat-icon-button color="primary" matTooltip="Download Order Report" [class.spinner]="ifExport" [disabled]="ifExport" (click)="downloadOrderReport()"> 
        <img src="/assets/icon/file.png" alt="" style="height:22px;"></button>
      &nbsp;
      
      <button mat-icon-button color="primary" matTooltip="Refresh the table" (click)="refresh_details()" [disabled]="disabled1" [class.spinner]='disabled1' ><mat-icon>autorenew</mat-icon></button>
      &nbsp; 

    </div>
  </div>
    </form>
  </div>
    <!-- <div>
      <mat-slide-toggle style="font-size: 1rem;font-weight: 500;"> Show Only today's Orders</mat-slide-toggle>
    </div> <div class="paddington border_bottom" >-->
  
    <div class="row">
      
    
    
    <div class="col-lg-6" style="background-color: white;">
      &nbsp; &nbsp;
      <span *ngIf="selection.selected.length > 0" style="padding-right:10px;font-size: smaller; margin: 0 20px">{{selection.selected.length}} records selected </span><span *ngIf="selection.selected.length > 0"><button mat-icon-button color="primary" (click)="processOrder()" matTooltip=" Process Order" [disabled]="disabled" [class.spinner]='disabled'><mat-icon>published_with_changes</mat-icon>&nbsp;&nbsp;</button></span>
      &nbsp; &nbsp;

      <!-- <button mat-raised-button class="ml-2"  color="primary" [class.spinner]="ifExport" [disabled]="ifExport" (click)="downloadOrderReport()"> <img src="../assets/icon/file_download.png"  style="height:24px;"></button>
      &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
      <span *ngIf="selection.selected.length > 0" style="padding-right:10px;font-size: smaller;">{{selection.selected.length}} records selected </span><span *ngIf="selection.selected.length > 0"><button mat-flat-button color="primary" (click)="processOrder()" matTooltip=" Process Order" [disabled]="disabled" [class.spinner]='disabled'><mat-icon>published_with_changes</mat-icon>&nbsp;&nbsp;</button></span>
      &nbsp; &nbsp;

      <button mat-flat-button color="primary" matTooltip="Refresh the table" (click)="refresh_details()" [disabled]="disabled1" [class.spinner]='disabled1' ><mat-icon>autorenew</mat-icon></button>
      &nbsp; &nbsp;  -->
      <!-- <button mat-icon-button color="primary" matTooltip="Generate Delivery Slips" (click)="generateDeliverySlips()" ><mat-icon>receipt</mat-icon></button>    -->
      
      <!-- <button  mat-flat-button color="primary" matTooltip="Reset" (click)="reset()">Reset </button>
      
      <button mat-flat-button color="primary" matTooltip="Refresh" (click)="refresh_details()" [disabled]="disabled1" [class.spinner]='disabled1' ><mat-icon>refresh</mat-icon></button>
      Download button hided because functionality not implemented -->
      <!-- <button mat-flat-button color="primary" matTooltip=" Download order Report" (click)="downloadReport()" [disabled]="disabled1" [class.spinner]='disabled1' style="margin: 10px 20px;"><mat-icon>download</mat-icon></button> -->
    </div>
    
   
  <mat-paginator *ngIf="!isSearch" class="col-lg-6" #paginator style="background-color: white;"
    [length]="totalOrders"
    [pageIndex]="0"
    [pageSize]="25"
    [pageSizeOptions]="[10,20,50]"
    showFirstLastButtons="true"
    (page)="pageChanged($event)">
</mat-paginator>



    <!-- <button mat-flat-button color="primary" ><mat-icon>payments</mat-icon> Add Payment</button> -->
    <!-- <button mat-flat-button color="accent" ><mat-icon>add_shopping_cart</mat-icon> New Orders</button> -->
    <!-- <div *ngIf="hasRoute('live-orders')"><button mat-flat-button color="primary"><mat-icon>description</mat-icon> Download Order Report</button></div> -->
  </div>
   
    <!-- <button mat-flat-button color="primary" ><mat-icon>payments</mat-icon> Add Payment</button> -->
    <!-- <button mat-flat-button color="accent" ><mat-icon>add_shopping_cart</mat-icon> New Orders</button> -->
    <!-- <div *ngIf="hasRoute('live-orders')"><button mat-flat-button color="primary"><mat-icon>description</mat-icon> Download Order Report</button></div> -->
   <!--  <div class="col-lg-3" *ngIf="!isSearch">
       <mat-form-field appearance="legacy">
        <input matInput (keyup)="applyFilter($event)" placeholder="Search" >
        <mat-icon matSuffix>search</mat-icon>
        
      </mat-form-field> 
     
  </div> -->
    
  
  <div>
    <div class="overlay" *ngIf="disabled||disabled1">
      <mat-spinner *ngIf="disabled||disabled1" class="absolute-center"></mat-spinner>
    </div>
  <table *ngIf="!isSearch" mat-table [dataSource]="dataSource | paginate: { itemsPerPage: 25, currentPage: page, totalItems:totalOrders}" style="width: 100%;">
    <!-- checkbox Column -->

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      color="primary"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      color="primary"
                      (change)="$event ? selection.toggle(row) : null;isSomeSelected()"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="orderId">
      <th mat-header-cell *matHeaderCellDef >ID</th>
      <td mat-cell *matCellDef="let row">{{row.orderDisplayName||row.orderId}}</td>
    </ng-container>

    <!-- buyerName Column -->
    <ng-container matColumnDef="buyerName">
      <th mat-header-cell *matHeaderCellDef >Buyer</th>
      <td mat-cell *matCellDef="let row">{{row.retailerShop.retailerShopName}}</td>
    </ng-container>

    <ng-container matColumnDef="buyerGroup">
      <th mat-header-cell *matHeaderCellDef >Group</th>
      <td mat-cell *matCellDef="let row">{{row.retailerShop.retailerGroupName}}</td>
    </ng-container>


        <!-- Name Column -->
    <ng-container matColumnDef="submittedUserName">
      <th mat-header-cell *matHeaderCellDef >Submitted User</th>
      <td mat-cell *matCellDef="let row" ><span [ngClass]="{'salesMan': row.user.userType=='Salesman','buyer':row.user.userType !='Salesman'}">{{row.user.userName}} </span></td>
    </ng-container>

    <!-- submittedUserPhone Column -->
    <ng-container matColumnDef="submittedDate">
      <th mat-header-cell *matHeaderCellDef >Submitted Date</th>
      <td mat-cell *matCellDef="let row">{{formatDate(row.orderedDate)}}</td>
    </ng-container>

    <!-- estimatedDate Column -->
    <ng-container matColumnDef="estimatedDate">
      <th mat-header-cell *matHeaderCellDef >Delivery Due Date</th>
      <td mat-cell *matCellDef="let row">{{row.deliveryTime.includes(':')?(row.deliveryTime| date:'dd MMM yyyy'):row.deliveryTime}}</td>
    </ng-container>

    <!-- amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef >Amount</th>
      <td mat-cell *matCellDef="let row" ><span style="display:flex !important;
        justify-content:flex-end!important;padding-right:20%!important;">
{{row.grandTotal | currency : row.totalAmount.currency : 'symbol' }}</span></td>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef >Status</th>
      <td mat-cell *matCellDef="let row"><span [ngStyle]="{'color':statusColour(row.status.value)}">{{row.status.value}}</span>
      
    </td>
    </ng-container>
    <ng-container matColumnDef="financialStatus">
      <th mat-header-cell *matHeaderCellDef >Payment Status</th>
      <td mat-cell *matCellDef="let row">{{row.finacialStatus || 'Not Recorded'}}</td>
    </ng-container>

    <!-- location Column   -->


  <ng-container matColumnDef="location" *ngIf="gpsTrackingEnabled">
    <th mat-header-cell *matHeaderCellDef >Location</th>
    <td mat-cell *matCellDef="let row">{{row.location?.locationName || 'Not Applicable'}}</td>
  </ng-container>

   <!-- actions Column -->

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef >Actions</th>
      <td mat-cell *matCellDef="let row">
      
        <button  mat-icon-button class="example-icon editButton" matTooltip="View Details" (click)="toDetails(row.orderId,tableData.indexOf(row))" (click)="$event.stopPropagation()">
          <img src="../assets/icon/view.png"  alt="" style="height:24px;">
         
        </button>
       <!--  <button mat-icon-button class="example-icon editButton" (click)="toEditOrder(row)" (click)="$event.stopPropagation()" matTooltip="Edit Order">
          <img src="../assets/icon/editorder.png"  alt="" style="height:24px;">
         
        </button> -->

      <button mat-icon-button *ngIf="row.status.value === 'Accepted'" class="example-icon editButton" matTooltip="Add Discount" (click)="openDialogDiscount(row)" (click)="$event.stopPropagation()">
        <img src="../assets/icon/discount.png"  alt="" style="height:24px;">
       
      </button>
      <button mat-icon-button  *ngIf="(row.status.value ==='Submitted'||row.status.value === 'Pending' || row.status.value === 'Accepted' ||row.status.value === 'Shipped' || row.status.value === 'Delivered') && (row.finacialStatus !== 'PAID')" class="example-icon editButton" (click)="openDialog(row)" (click)="$event.stopPropagation()" matTooltip="Add Payment">
        <img src="../assets/icon/addpayment.png"  alt="" style="height:24px;">
       
      </button>
      <button mat-icon-button class="example-icon editButton" matTooltip="Proforma Invoice" (click)="toProForma(row)"  (click)="$event.stopPropagation()">
        <img src="../assets/icon/invoice.png"  alt="" style="height:24px;">
       
      </button>
      <button mat-icon-button class="example-icon editButton" matTooltip="Delivery Slip" (click)="openDeliverySlip(row)" (click)="$event.stopPropagation()">
        <img src="../assets/icon/deliveryslip.png"  alt="" style="height:24px;">
       
      </button>

      <button   *ngIf="row.status.value === ('Submitted')||row.status.value === ('Pending')" mat-icon-button class="example-icon editButton" (click)=" processOrderById(row,row.orderId,'ACCEPT')" (click)="$event.stopPropagation()" matTooltip="Accept Order">
        <img src="../assets/icon/acceptorder.png"  alt="" style="height:24px;">
       
      </button>
      <button  *ngIf="row.status.value === ('Submitted')||row.status.value === ('Pending')" mat-icon-button class="example-icon editButton" (click)=" processOrderById(row,row.orderId,'REJECT')" (click)="$event.stopPropagation()" matTooltip="Reject Order">
        <img src="../assets/icon/rejectorder.png"  alt="" style="height:24px;">
       
      </button>
      <button  *ngIf="row.status.value === ('Accepted')" mat-icon-button class="example-icon editButton" (click)=" processOrderById(row,row.orderId,'SHIP')" (click)="$event.stopPropagation()" matTooltip="Ship Order">
        <img src="../assets/icon/shiporder.png"  alt="" style="height:24px;">
       
      </button>

      <button *ngIf="row.status.value === ('Shipped')" mat-icon-button class="example-icon editButton" (click)=" processOrderById(row,row.orderId,'DELIVER')" (click)="$event.stopPropagation()" matTooltip="Deliver Order">
        <img src="../assets/icon/deliverorder.png"  alt="" style="height:24px;">
       
      </button>
      
    </td>
    </ng-container>
  

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background-color: #ffffff;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight':hightlight(row)}" (click)="toDetails(row.orderId,tableData.indexOf(row))" style="cursor: pointer;" class="color"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="100" align="center">No Orders Available</td>
    </tr>
  </table>

  <mat-paginator *ngIf="isSearch" #paginatorSearch style="background-color: white;"
  [length]="totalSearchOrders"
  [pageIndex]="0"
  [pageSize]="25"
  [pageSizeOptions]="[10,20,50]"
  showFirstLastButtons="true"
  (page)="pageChangedSearch($event)">
</mat-paginator>


  <table *ngIf="isSearch" mat-table [dataSource]="searchDataSource" style="width: 100%;">
    <!-- checkbox Column -->
    

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle1() : null"
                      color="primary"
                      [checked]="selection.hasValue() && isAllSelected1()"
                      [indeterminate]="selection.hasValue() && !isAllSelected1()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      color="primary"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="orderId">
      <th mat-header-cell *matHeaderCellDef >Order ID</th>
      <td mat-cell *matCellDef="let row">{{row.orderDisplayName||row.orderId}}</td>
    </ng-container>

    <!-- buyerName Column -->
    <ng-container matColumnDef="buyerName">
      <th mat-header-cell *matHeaderCellDef >Buyer</th>
      <td mat-cell *matCellDef="let row">{{row.retailerShop.retailerShopName}}</td>
    </ng-container>

    <ng-container matColumnDef="buyerGroup">
      <th mat-header-cell *matHeaderCellDef >Group</th>
      <td mat-cell *matCellDef="let row">{{row.retailerShop.retailerGroupName}}</td>
    </ng-container>


        <!-- Name Column -->
    <ng-container matColumnDef="submittedUserName">
      <th mat-header-cell *matHeaderCellDef >Submitted User</th>
      <td mat-cell *matCellDef="let row" ><span [ngClass]="{'salesMan': row.user.userType=='Salesman','buyer':row.user.userType !='Salesman'}">{{row.user.userName}} </span></td>
    </ng-container>

    <!-- submittedUserPhone Column -->
    <ng-container matColumnDef="submittedDate">
      <th mat-header-cell *matHeaderCellDef >Submitted Date</th>
      <td mat-cell *matCellDef="let row">{{formatDate(row.orderedDate)}}</td>
    </ng-container>

    <!-- estimatedDate Column -->
    <ng-container matColumnDef="estimatedDate">
      <th mat-header-cell *matHeaderCellDef >Delivery Due Date</th>
      <td mat-cell *matCellDef="let row">{{row.deliveryTime.includes(':')?(row.deliveryTime| date:'dd MMM yyyy'):row.deliveryTime}}</td>
    </ng-container>

    <!-- amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef >Amount</th>
      <td mat-cell *matCellDef="let row" ><span style="display:flex !important;
        justify-content:flex-end!important;padding-right:20%!important;">
{{row.grandTotal | currency : row.totalAmount.currency: 'symbol'}}</span></td>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef >Status</th>
      <td mat-cell *matCellDef="let row"><span [ngStyle]="{'color':statusColour(row.status.value)}">{{row.status.value}}</span>
      
    </td>
    </ng-container>

    <ng-container matColumnDef="financialStatus">
      <th mat-header-cell *matHeaderCellDef >Payment Status</th>
      <td mat-cell *matCellDef="let row">{{row.finacialStatus || 'Not Recorded'}}</td>
    </ng-container>

     <!-- location Column -->

    <ng-container matColumnDef="location" *ngIf="gpsTrackingEnabled">
      <th mat-header-cell *matHeaderCellDef >Location</th>
      <td mat-cell *matCellDef="let row">{{row.location?.locationName || 'Not Applicable'}}</td>
    </ng-container>

  <!-- actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef >Actions</th>
      <td mat-cell *matCellDef="let row">
        <button  mat-icon-button class="example-icon editButton" matTooltip="View Details" (click)="toDetails(row.orderId,tableData.indexOf(row))" (click)="$event.stopPropagation()">
          <img src="../assets/icon/view.png"  alt="" style="height:24px;">
         
        </button>
       

      <button mat-icon-button *ngIf="row.status.value === 'Accepted'" class="example-icon editButton" matTooltip="Add Discount" (click)="openDialogDiscount(row)" (click)="$event.stopPropagation()">
        <img src="../assets/icon/discount.png"  alt="" style="height:24px;">
       
      </button>
      <button mat-icon-button  *ngIf="row.status.value ==='Submitted'||row.status.value === 'Pending' || row.status.value === 'Accepted' || row.status.value === 'Shipped' || row.status.value === 'Delivered'" class="example-icon editButton" (click)="openDialog(row)" (click)="$event.stopPropagation()" matTooltip="Add Payment">
        <img src="../assets/icon/addpayment.png"  alt="" style="height:24px;">
       
      </button>
      <button *ngIf="row.status.value !=='Rejected' && row.status.value !== 'Cancelled'" mat-icon-button class="example-icon editButton" matTooltip="Proforma Invoice" (click)="toProForma(row)"  (click)="$event.stopPropagation()">
        <img src="../assets/icon/invoice.png"  alt="" style="height:24px;">
       
      </button>
      <button *ngIf="row.status.value !=='Rejected' && row.status.value !== 'Cancelled'" mat-icon-button class="example-icon editButton" matTooltip="Delivery Slip" (click)="openDeliverySlip(row)" (click)="$event.stopPropagation()">
        <img src="../assets/icon/deliveryslip.png"  alt="" style="height:24px;">
       
      </button>

      <button   *ngIf="row.status.value === ('Submitted')||row.status.value === ('Pending')" mat-icon-button class="example-icon editButton" (click)=" processOrderById(row,row.orderId,'ACCEPT')" (click)="$event.stopPropagation()" matTooltip="Accept Order">
        <img src="../assets/icon/acceptorder.png"  alt="" style="height:24px;">
       
      </button>
      <button  *ngIf="row.status.value === ('Submitted')||row.status.value === ('Pending')" mat-icon-button class="example-icon editButton" (click)=" processOrderById(row,row.orderId,'REJECT')" (click)="$event.stopPropagation()" matTooltip="Reject Order">
        <img src="../assets/icon/rejectorder.png"  alt="" style="height:24px;">
       
      </button>
      <button  *ngIf="row.status.value === ('Accepted')" mat-icon-button class="example-icon editButton" (click)=" processOrderById(row,row.orderId,'SHIP')" (click)="$event.stopPropagation()" matTooltip="Ship Order">
        <img src="../assets/icon/shiporder.png"  alt="" style="height:24px;">
       
      </button>

      <button *ngIf="row.status.value === ('Shipped')" mat-icon-button class="example-icon editButton" (click)=" processOrderById(row,row.orderId,'DELIVER')" (click)="$event.stopPropagation()" matTooltip="Deliver Order">
        <img src="../assets/icon/deliverorder.png"  alt="" style="height:24px;">
       
      </button>
     
      
    </td>
    </ng-container>
  

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background-color: #ffffff;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight':hightlight(row)}" (click)="toDetails(row.orderId,tableData.indexOf(row))" style="cursor: pointer;" class="color"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="100" align="center">No Orders Available</td>
    </tr>
  </table>
  
  </div>

  
</div>
